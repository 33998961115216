import { useEffect, useState } from "react";
import { connectWallet, getCurrentWalletConnected } from "./interact.js";
import Web3 from "web3"
import {ADDRESS, ABI} from "./config.js"
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

import './static.css';



const Static = (props) => {
  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [how_many_clowns, set_how_many_clowns] = useState(1)
  const [contract, setContract] = useState(null)
  const [totalSupply, setTotalSupply] = useState(0)
  const [saleStarted, setSaleStarted] = useState(false)
  const [presaleStarted, setPresaleStarted] = useState(false)
  const [price, setPrice] = useState(0)
  const [presaleMaxx, setPresaleMax] = useState(1)
  const [instantiated, setInstantiated] = useState(false)
  
  function valuetext(value) {
     if(value === 3)
     {
        value = 5
     }
     if(value === 4)
     {
        value = 10
     }
     if(value === 5)
     {
        value = 20
     }
    set_how_many_clowns(value);
  }
  const sliderMarks = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '5',
    },
    {
      value: 4,
      label: '10',
    },
    {
      value: 5,
      label: '20',
    },
  ];

  useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();
    setWallet(address)
    setStatus(status);
    instantiate();
    callContractData();
    addWalletListener();
  }, []);

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintPressed = async () => { 
    if (contract) {
       try{
        const clownPrice = Number(price)  * how_many_clowns

        const gasAmount = await contract.methods.mint(how_many_clowns).estimateGas({from: walletAddress, value: clownPrice})
        console.log("estimated gas",gasAmount)
  
        console.log({from: walletAddress, value: price})

        contract.methods
        .mint(how_many_clowns)
        .send({from: walletAddress, value: clownPrice, gas: String(gasAmount)})
        .on('transactionHash', function(hash){
          console.log("transactionHash", hash)
        })
      }
      catch(err){
         alert(JSON.stringify(err))
      }
        
    } else {
        console.log("Wallet not connected")
    }

  };

  async function instantiate() {
    if (typeof window.web3 !== 'undefined') {
      // Use existing gateway
      window.web3 = new Web3(window.ethereum);
     
    } else {
  
    }

    window.ethereum.enable()
      .then(function (accounts) {
        window.web3.eth.net.getNetworkType()
        // checks if connected network is mainnet
        .then((network) => {console.log(network);if(network != "main"){alert("You are on " + network+ " network. Change network to mainnet or you won't be able to do anything here")} });  
        let wallet = accounts[0]
        setWallet(wallet)
        setInstantiated(true)
        callContractData(wallet)

  })
  .catch(function (error) {
  // Handle error. Likely the user rejected the login
  console.error(error)
  })
  }

  async function callContractData() {
    const clownContract = new window.web3.eth.Contract(ABI, ADDRESS)
    setContract(clownContract)

    const totalSupply = await clownContract.methods.totalSupply().call() 
    setTotalSupply(totalSupply)

    const salebool = await clownContract.methods.saleIsActive().call() 
    setSaleStarted(salebool)

    const presalebool = await clownContract.methods.presaleIsActive().call()
    setPresaleStarted(presalebool)

    const preSaleMax = await clownContract.methods.PRESALE_SUPPLY().call()
    setPresaleMax(preSaleMax)

    const clownPrice = await clownContract.methods.price().call() 
    setPrice(clownPrice)
  }

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("");
        } else {
          setWallet("");
          setStatus("Connect your Metamask wallet to mint 🦊");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  return (
   <div className="overflow">
   <header className="header">
      <div className="cont flex">
         <div className="header__top flex">
            <div className="social flex animated">
               <a target="_blank" href="https://www.instagram.com/cryptoclowns_nft/"><img src="/soc1.svg" alt=""/></a>
               <a target="_blank" href="https://twitter.com/cryptoclowns_"><img src="/soc2.svg" alt=""/></a>
               <a target="_blank" href="https://discord.gg/cVGRYceA8c"><img src="/soc3.svg" alt=""/></a>
               <a target="_blank" href=""><img src="/soc4.svg" alt=""/></a>
            </div>
            <button className="btn header__btn" onClick={connectWalletPressed}>
               {walletAddress.length > 0 ? (
                  "Connected: " +
                  String(walletAddress).substring(0, 6) +
                  "..." +
                  String(walletAddress).substring(38)
               ) : (
                  <span>Connect Metamask Wallet</span>
               )}
            </button>

         </div>
         

         <div className="header__logo">
            <picture>
               <source media="(min-width: 1440px)" srcset="/logo1.png" />
               <source media="(min-width: 768px)" srcset="/logo1440.png" />
               <img src="/logo768.png" alt="CryptoClowns" />
            </picture>
         </div>
         
         <nav className="header__nav flex">
            <a data-scroll="#mint">mint</a>
            <a data-scroll="#lore">LORE</a>
            <a data-scroll="#clowns">the clowns</a>
            <a data-scroll="#roadmap">Roadmap</a>
            <a data-scroll="#team">the team</a>
            <a data-scroll="#faq">FAQ</a>
         </nav>

         <p className="header__text">
            CryptoClowns is a masterful collection of 7,666 randomly generated 3-dimensional NFTs on the Ethereum Blockchain. 7,666 unpredictable clowns have escaped the Circus, and are currently on the loose! Beware, some of them are well mannered, some are real killers. You can capture up to 20 CryptoClowns at a time on November 28th, to do your part in preventing these psychos from wreaking havoc on society! 
         </p>
      </div>
   </header>

      <section className="mint" id="mint">
         <div className="cont">

            <h2 className="slider__title">mint</h2>
            <div className="slider">
               
            <Slider
               defaultValue={1}
               getAriaValueText={valuetext}
               aria-labelledby="discrete-slider"
               valueLabelDisplay="auto"
               step={1}
               marks={sliderMarks}
               min={1}
               max={5}
            />
            </div>

            <button id="mintButton" onClick={onMintPressed} className="btn">
            {"Mint"}
            </button>
         </div>
      </section>

      <section className="lore" id="lore">
         <h2>Lore</h2>
         <div className="cont flex flex-2col">
            <div>
               <img src="/lore.jpg" alt=""/>
            </div>

            <div>
               <p>A virus has escaped from a research facility in China and is
                  now infecting the entire world.  The effects of this virus are
                  uncommon... If you are contaminated, your skin will start
                  turning a pale white and your hair will become colourful
                  turning into a bright yellow, purple or even greenish color!
                  Your blood will coagulate at your body’s extremities and
                  your nose will become reddish... You will also feel the urge
                  to eat human flesh & blood. 
               </p>
               <p>	
                  As the virus spreads through the world population, society
                  becomes chaotic, essential services are stopped and buildings
                  start to crumble. Simple ressources like food & water are now
                  as scarce as gold. Also, the infected, are roaming around in
                  search of fresh humans everywhere. Survivors call these
                  infected people Clowns, as they literally look like Psychotic
                  Clowns. In order to survive, the non-infected have no choice
                  but to dress up as Clowns... trying to fool the infected ones... 
               </p>	
               <p>	
                  Looting the best ressources, weapons, food & drinks is a must!
                  Build your secret hideout, set up your defences,  find & craft
                  better weapons and level up your character for your best
                  chances at survival and at saving what is left of the human race.
                  Do everything you can to get an edge in this chaotic clown world.
                  But no matter what... do not get  eaten or bitten by these infected
                  deranged Clowns or you will yourself become one of them.
               </p>
               <p>
                  How long can you survive in this
                  Dystopian World of Good & Evil Clowns...?
               </p>
            </div>
         </div>
      </section>

      <section className="banner"> 
         <picture>
               <source media="(min-width: 1440px)" srcset="/banner.jpg" />
               <source media="(min-width: 768px)" srcset="/banner1440.jpg" />
               <img src="/banner768.jpg" alt="How Long Can You Survive?" />
            </picture>
      </section>

      <section className="clowns" id="clowns">
         <h2>collection</h2>
         <div className="cont grid">
            <img src="/collection/1.jpg" alt=""/>
            <img src="/collection/2.jpg" alt=""/>
            <img src="/collection/3.jpg" alt=""/>
            <img src="/collection/4.jpg" alt=""/>
            <img src="/collection/5.jpg" alt=""/>
            <img src="/collection/6.jpg" alt=""/>
            <img src="/collection/7.jpg" alt=""/>
            <img src="/collection/8.jpg" alt=""/>
         </div>
      </section>

      <section className="roadmap" id="roadmap">
         <div className="cont">
            <h2>ROADMAP</h2>
            <div className="roadmap__phase">
               <h3>PHASE 1<br/>
                  CLOWNS DROP</h3>
               <p>
                  0%- The CryptoClowns are on the loose as ETH tokens and ready to mint  and lock up in your wallet. Our dev team has already caught 100 of these clowns for promotions and giveaways. 
               </p>
               
               
               <p>25% - First 2,000 NFT Clown holders will have access to Free Airdrops, Special Unique Skins
               & Early Game Access (Beta) when game is nearing completion.</p>
               
               <p>50 clowns will be raffled and airdropped to the first 666 active clown holders. It’s important
               to mint first and mint fast!
               </p>
               <p>
                  50% - Raffle 1of1 Mutant Clown Ape + Mutant Ape (3.8eth currently)
               </p>
               
               <p>Use proceeds to immediately grow the CryptoClowns community with giveaways and
               marketing campaigns on Discord, Twitter, and Instagram. Use proceeds to sweep floor and
               stimulate healthy growth.</p>
               
               <p>
                  Listing on Rarity Tools and Opensea Verification
               </p>
               
               <p>75% - 50 Golden Clown Headset Trait: 50 PS5 will be given to 50 lucky holders of the
               Golden Clown Headset Trait</p>
               
               <p>100% - 50 Golden Clown Balloon Trait: 50x1000$ usd in eth airdropped to 50 lucky holders
               of the Golden Clown Balloon Trait wallet</p>
               
               <p>
                  Launch of CryptoClowns Premium Merch store for verified Clown holders
               </p>
            </div>
            <div className="roadmap__phase">
               <h3>PHASE 2 <br/>
                  GAME DEVELOPMENT & MERCH</h3>
                  <p>
                     #1 - Design and Production of the entire CryptoClowns merchandise line for sale on our site
                  </p>

                  <p>#2 - Redesign of All Clowns NFT to HD Gaming Quality. We will also be working with a 3D
                  Toy Manufacturer to offer your Clowns as Real-World Collectables</p>
                  
                  <p>
                     #3 - Development & Launch of The Survival Horror Metaverse CryptoClowns Game
                  </p>
                  
                  <p>#4 - Key Partnerships with Gaming Companies, Gamers, Brands, and Companies to expand
                  our communies reach and accelerate growth</p>
            </div>
            <div className="roadmap__phase">
               <h3>PHASE 3 <br/>
               ECOSYSTEM & COMMUNITY EXPANSION</h3>
               <p>
                  #1 - Expansion on Multiplatform & Incorporate Support for multiple Languages
               </p>

               <p>
                  #2 - Free Accessories & Items Airdrops for 2000 first holders!
               </p>

               <p>#3 - Expansion of Game to integrate with other Metaverses and Partnerships with other
               Metaverse Companies</p>
            </div>
         </div>
      </section>

      <section className="team" id="team">
         <h2>The Team</h2>
         <div className="cont grid">
            <div>
               <img src="" alt="" src="/team/1.jpg" />
               <h4>Maniac Clown</h4>
               <h5>Head of Operations</h5>
               <p>Enjoyed long walks on the
                  beach prior to the
                  clown outbreak.</p>
            </div>
            <div>
               <img src="" alt="" src="/team/2.jpg" />
               <h4>Bill Gutsky</h4>
               <h5>Design Director</h5>
               <p> “The streets is a short stop.
                  Either you’re slinging
                  crack rock or you got a
                  wicked jump shot.” B.I.G.</p>
            </div>
            <div>
               <img src="" alt="" src="/team/3.jpg"/>
               <h4>Vansh J</h4>
               <h5>Core Capital</h5>
               <p>Asia’s own Mark Zuckerberg.
                  When he’s not dominating 
                  the NFT space, he’s
                  slaying trim.</p>
            </div>
            <div>
               <img src="" alt="" src="/team/4.jpg" />
               <h4>Manuel MG</h4>
               <h5>Marketing Afficionado</h5>
               <p>Probabaly smarter than everyone
                  you’ve ever met. Canada’s
                  proudest export.</p>
            </div>
         </div>
      </section>
      
      <section className="faq" id="faq">
         <div className="cont faq__list">
            <h2 className="animated">FAQ</h2>
            <div className="animated">
               <div className="question">What are CryptoClowns?</div>
               <div className="answer">CryptoClowns are a group of 7,666 unique NFT randomly generated and stored on the blockchain. This Digital Collection is securely stored in your ethereum wallet as an NFT asset that you can sell or trade.</div>
            </div>
            <div className="animated">
               <div className="question">Where do I buy a CryptoClown?</div>
               <div className="answer">You can Mint CryptoClowns using our website, or head over to <a href="https://www.opens.io">opens.io</a> after the launch date.</div>
            </div>
            <div className="animated">
               <div className="question">How much does a CryptoClown cost?</div>
               <div className="answer">CryptoClown Mint fee is 0.0666 ETH each.</div>
            </div>
            <div className="animated">
               <div className="question">Can I buy more than one?</div>
               <div className="answer">You can buy up to 20 CryptoClowns per wallet. We want to make sure everyone who is interested gets their chance to mint.</div>
            </div>
            <div className="animated">
               <div className="question">Can I buy it on a Mobile device?</div>
               <div className="answer">You can mint on mobile! Connect your wallet using the button above and you can mint right from your phone.</div>
            </div>
            <div className="animated">
               <div className="question">How do I get in touch with the CryptoClowns Team?</div>
               <div className="answer">The best way to get in touch with our team is through our discord channel!</div>
            </div>
         </div>
      </section>


      <footer className="footer">
         <a href="https://nftcalendar.io/event/cryptoclowns/" target="_blank" class="nft flex">As seen on <img src="/nft.svg" alt="NFT Calendar"/></a>
         <div className="social flex">
            <a target="_blank" href="https://www.instagram.com/cryptoclowns_nft/"><img src="/soc1.svg" alt=""/></a>
            <a target="_blank" href="https://twitter.com/cryptoclowns_"><img src="/soc2.svg" alt=""/></a>
            <a target="_blank" href="https://discord.gg/cVGRYceA8c"><img src="/soc3.svg" alt=""/></a>
            <a target="_blank" href=""><img src="/soc4.svg" alt=""/></a>
         </div>
      </footer>
   
      <div className="container">
      </div>   
</div>

  )
};

export default Static;
